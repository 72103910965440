import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import useIntl from '../useIntl';
import { DateTime } from 'luxon';
import Timeline from './timeline/Timeline';
import TimelineItem from './timeline/TimelineItem';
import TimelineBadge from './timeline/TimelineBadge';
import { ArrowUpwardOutlined, ArrowDownwardOutlined, CircleOutlined } from '@mui/icons-material';
import TimelinePanel from './timeline/TimelinePanel';
import TimelineHeading from './timeline/TimelineHeading';
import TimelineBody from './timeline/TimelineBody';
import _ from 'lodash';
import { Alert, Box, Button, CircularProgress, Typography } from '@mui/material';
import useBalanceCard from '../useBalanceCard';
import { getPaymentProfileName } from '../utils/paymentProfiles';
import { useTranslation } from 'react-i18next';

function BalanceUpdates() {
    const { t } = useTranslation('balanceCard');

    const params = useParams();
    const { pathname } = useLocation();
    const { currencyFormat } = useIntl();
    const navigate = useNavigate();
    const { setSerialNumber, balanceUpdates, loading, events, requiresRegistration } = useBalanceCard();

    useEffect(() => {
        populateBalanceUpdates();
    }, [params, pathname, events, requiresRegistration]);

    const populateBalanceUpdates = async () => {
        if (params.serialNumber.indexOf(':') === -1) {
            if (params.serialNumber.length >= 16) {
                const serialNumber = `0${Number(params.serialNumber).toString(16)}`.toUpperCase();
                navigate(`/${encodeURIComponent(serialNumber)}`, { replace: true });

                return;
            } else {
                const serialNumber = params.serialNumber.replaceAll(/.{2}\B/g, '$&:');
                navigate(`/${encodeURIComponent(serialNumber)}`, { replace: true });

                return;
            }
        } else if (pathname.indexOf(':') !== -1) {
            navigate(`/${encodeURIComponent(params.serialNumber)}`, { replace: true });

            return;
        }

        setSerialNumber(params.serialNumber);

        if (events !== null && requiresRegistration) {
            navigate(`/${encodeURIComponent(params.serialNumber)}/register`);
        }
    };

    const handleTopupClick = () => {
        navigate('topup');
    };

    const hasZak = (balanceUpdates) => {
        let hasZak = false;

        for (let u of balanceUpdates) {
            console.log(u.event.name, u);
            if (u.event.name === 'Zak 2024-2025') {
                hasZak = true;
            }
        }

        return hasZak;
    };

    return (
        <>
            {balanceUpdates.length <= 0 && !loading && (
                <Box
                    display="flex"
                    sx={{
                        justifyContent: 'center',
                        marginBottom: '1rem'
                    }}
                >
                    <Alert
                        severity="warning"
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '50%',
                                xl: '40%'
                            }
                        }}
                    >
                        {t('Deze balanskaart is nog niet bij ons bekend.')}
                    </Alert>
                </Box>
            )}
            <Box
                sx={{
                    textAlign: 'center'
                }}
            >
                {!hasZak(balanceUpdates) && (
                    <Button variant="contained" onClick={handleTopupClick}>
                        {t('Online opwaarderen')}
                    </Button>
                )}
            </Box>
            <br />
            {loading && (
                <Box sx={{ textAlign: 'center', pt: 8 }}>
                    <CircularProgress />
                </Box>
            )}
            {balanceUpdates.map((event) => (
                <React.Fragment key={event.event.id}>
                    <Typography
                        variant="h6"
                        sx={{
                            textAlign: 'center'
                        }}
                    >
                        {event.event.name}
                    </Typography>
                    <Timeline key={event.event.id}>
                        <TimelineItem inverted={true}>
                            <TimelineBadge icon={<CircleOutlined />} color="success" />

                            <TimelinePanel>
                                <TimelineHeading
                                    title={t('Huidig saldo')}
                                    subtitle={DateTime.now().toLocaleString(DateTime.DATETIME_MED)}
                                />

                                <TimelineBody>
                                    <b>
                                        {t('Totaal')}: {currencyFormat.format(event.balanceCard.totalBalance)}
                                    </b>
                                </TimelineBody>
                            </TimelinePanel>
                        </TimelineItem>

                        {event.updates.length > 0 &&
                            event.updates.map((update) => (
                                <TimelineItem key={update.id} inverted={_.includes(update.type.toLowerCase(), 'topup')}>
                                    <TimelineBadge
                                        icon={
                                            _.includes(update.type.toLowerCase(), 'topup') ? (
                                                <ArrowUpwardOutlined />
                                            ) : (
                                                <ArrowDownwardOutlined />
                                            )
                                        }
                                        color={_.includes(update.type.toLowerCase(), 'topup') ? 'success' : 'danger'}
                                    />
                                    <TimelinePanel>
                                        <TimelineHeading
                                            title={_.includes(update.type.toLowerCase(), 'topup') ? t('Saldo') : t('Consumptie')}
                                            subtitle={update.date.toLocaleString(DateTime.DATETIME_MED)}
                                        />
                                        <TimelineBody>
                                            {Object.keys(update.products).map((product) => (
                                                <Typography key={`${update.id}${update.products[product].id}`}>
                                                    {update.products[product].amount}× {product}
                                                    {' — '}
                                                    {currencyFormat.format(update.products[product].total)}
                                                </Typography>
                                            ))}
                                            <br />
                                            {update.payments?.length > 0 && (
                                                <>
                                                    <b>Betalingen</b>
                                                    <br />
                                                    {update.payments.map((payment) => (
                                                        <React.Fragment key={`${update.id}${payment.paymentProfile}${payment.amountPaid}`}>
                                                            <Typography>
                                                                {getPaymentProfileName(payment.paymentProfile)}{' '}
                                                                {payment.balanceCardSerial != null && (
                                                                    <>
                                                                        (
                                                                        {payment.balanceCardSerial !== 'huidige kaart' && (
                                                                            <span
                                                                                style={{
                                                                                    filter: 'blur(4px)'
                                                                                }}
                                                                            >
                                                                                LI:KM:IJ:NK:UT
                                                                            </span>
                                                                        )}
                                                                        {payment.balanceCardSerial})
                                                                    </>
                                                                )}{' '}
                                                                — {currencyFormat.format(payment.amountPaid)}
                                                                <br />
                                                            </Typography>
                                                        </React.Fragment>
                                                    ))}
                                                </>
                                            )}
                                            <br />
                                            <b>
                                                {t('Totaal')}: {currencyFormat.format(update.amount)}
                                            </b>
                                        </TimelineBody>
                                    </TimelinePanel>
                                </TimelineItem>
                            ))}
                    </Timeline>
                    <br />
                </React.Fragment>
            ))}
        </>
    );
}

export default BalanceUpdates;
